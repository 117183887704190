import React, { useState, useEffect, useContext } from "react";
import { Layout } from "@layout";
import { Preview } from "@containers/Blogs";
import AdminContext from "@context";
import { navigate } from "gatsby";

import { sanitize } from "@utils";
import { addBlog } from "@services";

import Close from "@assets/icons/close.svg";

import {
	Flex,
	Col,
	Grid,
	Title,
	SubTitle,
	Text,
	Svg,
	Badge,
	Box,
	Textarea,
	Input,
	InputHelper,
	Select,
	CheckBox,
	Button,
	AddOption,
	Uploader,
	Modal,
} from "@components";

import { ErrorMessage } from "@components/Uploader/styles";
import UploaderControls from "@components/Uploader/uploaderControls";

const Tooltips = {
	timereading:
		"Define el tiempo de lectura estimado para este blog, como recomendaciòn el tiempo no debe ser menor a 3 minutos (700 palabras) y 6 minutos (1200 palabras), un lector promedio lee cerca de 250 palabras por minuto.",
	keyword:
		"Keyword: Esta keyword deb esta contenida dentro de toda la estrategía SEO del blog.",
	title:
		"Title: Crea un titulo SEO llamativo y conciso, no olvides incluir la keyword principal.",
	slug: "Slug: este es el nombre de la url amigable que más les encanta a los robots de Google. Es importante que contenga la keyword principal!",
	desc: "Descripción: Pequeño parrafo que describe el contenido del blog. No olvides agregar aquellas palabras clave más importantes, se claro en la descripción!",
	helpkeywords:
		"Ingresa las palaras clave separadas por coma, recuerda usar un lenguaje coloquial para el público objetivo.",
};

const AgregarBlog = () => {
	const {
		catalogos: { categories },
		auth: { token, user: author },
	} = useContext(AdminContext);
	const [data, setData] = useState({
		seotitle: "",
		description: "",
		timereading: "",
		keywords: [],
		metakeyword: "",
		category: "",
		leyend: null,
		thumbnail: null,
		cover: null,
	});

	const [url, setUrl] = useState("");
	const [keywords, setKeywords] = useState("");
	const [pressKey, setPressKey] = useState("");
	const [error, setError] = useState(false);
	const [errorUploadSize, setErrorUploadSize] = useState(false);
	const [errorUploadSize2, setErrorUploadSize2] = useState(false);
	const [request, setRequest] = useState(false);
	const [requesting, setRequesting] = useState(false);
	const [enableButton, setEnableButton] = useState(false);
	const [errorRegistry, setErrorRegistry] = useState(false);

	const handleChangeData = (key, value) => {
		setData({
			...data,
			[key]: value,
		});
	};

	const deleteKeyword = (keyword) => {
		const newKeywords = data.keywords.filter((item) => item !== keyword);
		handleChangeData("keywords", [...newKeywords]);
	};

	useEffect(() => {
		if (pressKey === "Enter" && keywords.length) {
			handleChangeData("keywords", [...data.keywords, sanitize(keywords)]);
			setKeywords("");
		}
	}, [pressKey]);

	useEffect(() => {
		setUrl(data.seotitle.toLowerCase().replace(/ /g, "-"));
	}, [data.seotitle]);

	useEffect(() => {
		const isValid =
			data.metakeyword.length > 3 &&
			data.seotitle.length > 3 &&
			url.length > 3 &&
			data.description.length > 3 &&
			data.timereading &&
			data.keywords.length > 0 &&
			data.category &&
			data.thumbnail?.validate &&
			data.cover?.validate;

		console.log(data);

		setEnableButton(isValid);
	}, [data, url]);

	const handleSaveBlog = () => {
		setRequesting(true);
		let newCategory = data.category;
		const category = categories.filter((c) => +c.id === +data.category)[0];

		if (category) {
			newCategory = category.label;
		}

		const newData = {
			...data,
			category: newCategory,
			keywords: data.keywords.join(","),
			slug: url.replace(/.,;:/g, ""),
			author,
		};

		addBlog(newData, token)
			.then((r) => {
				setRequesting(false);
				navigate("/admin/blogs");
			})
			.catch((err) => {
				setErrorRegistry(true);
				setRequesting(false);
			});
	};

	return (
		<Layout title='Agregar nuevo blog'>
			{errorRegistry && (
				<Modal show={errorRegistry} onClose={() => setErrorRegistry(false)}>
					<Text className="pt:2 ph:2" align="center" fs={14}>
						Ha ocurrido un error al registrar este blog, asegurate de que no lo
						hayas registrado previamente y que haz llenado toda la información.
					</Text>
				</Modal>
			)}

			<Grid className="pv:2">
				<Title>Agrega un nuevo blog</Title>

				<Flex className="mv:2" justify="between">
					<Col xs={12} md={10} className="ph:1">
						<SubTitle>Configuración SEO</SubTitle>
						<Box>

							<InputHelper tooltip={Tooltips.keyword}>
								<Input
									value={data.metakeyword}
									placeholder="Keyword principal del blog"
									getValue={(v) => handleChangeData("metakeyword", v)}
									onlyNL
									error={
										data.metakeyword.length > 50
											? "Tamaño de la keyword es superior a 50 caracteres"
											: ""
									}
								/>
							</InputHelper>

							<InputHelper tooltip={Tooltips.timereading}>
								<Input
									value={data.timereading}
									placeholder="Tiempo de lectura estimado en minutos"
									getValue={(v) => handleChangeData("timereading", v)}
									isNumber
									error={
										+data.timereading > 6
											? "El tiempo de lectura recomendado es entre 3 y 6 minutos"
											: ""
									}
								/>
							</InputHelper>

							<InputHelper tooltip={Tooltips.title}>
								<Input
									value={data.seotitle}
									placeholder="Titulo SEO"
									getValue={(v) => handleChangeData("seotitle", v)}
									onlyNL
									error={
										data.seotitle.length > 0 &&
										data.seotitle.length > 53 &&
										data.leyend
											? "Tamaño de titulo SEO superior a 60 caracteres"
											: data.seotitle.length > 60 && !data.leyend
											? "Tamaño de titulo SEO superior a 60 caracteres"
											: ""
									}
								/>
							</InputHelper>

							<InputHelper tooltip={Tooltips.slug}>
								<Input
									isSlug
									value={url}
									placeholder="Link permanente o slug"
									getValue={(v) => setUrl(v)}
									normalizer
									maxLength={data.leyend ? 53 : 60}
									error={
										url.length > 0 && url.length > 53 && data.leyend
											? "Tamaño de link superior a 60 caracteres"
											: url.length > 60 && !data.leyend
											? "Tamaño de link superior a 60 caracteres"
											: ""
									}
								/>
							</InputHelper>

							<InputHelper tooltip={Tooltips.desc}>
								<Textarea
									value={data.description}
									placeholder="Descripción SEO"
									getValue={(v) => handleChangeData("description", v)}
									onlyNL
									error={
										data.description.length > 0 && data.description.length > 150
											? "Tamaño de la descripción es superior a 150 caracteres"
											: ""
									}
								/>
							</InputHelper>

							<InputHelper tooltip="Opcional: puedes agregar el tipo de contenido dentro del titulo, para el caso de los Blog se agrega ' | Blog' al final del titulo SEO.">
								<CheckBox
									getValue={({ checked }) =>
										handleChangeData("leyend", checked)
									}
									name="leyend"
									id="leyend-c"
									value={1}
									selected={data?.leyend}
								>
									Quiero mostrar la etiqueta de Blog!
								</CheckBox>
							</InputHelper>

							<InputHelper tooltip="Categoria: Te permite darle una clasificación al blog, esta opción no afecta directamente al SEO, sólo es organizativa.">
								<Select
									getValue={(v) => handleChangeData("category", v)}
									placeholder="Selecciona una categoría del blog"
									value={data.category || ""}
									options={categories}
								/>
							</InputHelper>

							{+data.category === 9999 && (
								<AddOption
									typeOption="categoria"
									category="categories"
									getValue={(v) => handleChangeData("category", v)}
								/>
							)}

							<InputHelper tooltip={Tooltips.helpkeywords}>
								<Input
									value={keywords}
									placeholder="Keyword principal del blog"
									getValue={setKeywords}
									onKeyUp={({ key }) => setPressKey(key)}
									onlyNL
									error={""}
								/>

								{!!data.keywords.length && (
									<Flex className="mt:1">
										{data.keywords.map((keyword, index) => (
											<Badge regular key={index}>
												<span className="pr:05">{keyword}</span>
												<Svg
													title="Eliminar keyword"
													style={{ cursor: "pointer" }}
													onClick={() => deleteKeyword(keyword)}
													icon={Close}
													size="18:18"
													width={18}
													height={18}
												/>
											</Badge>
										))}
									</Flex>
								)}
							</InputHelper>

							<Flex>
								<Col xs={20} md={18}>
									<SubTitle className="mt:3 mb:1">
										Sube tu imagen destacada
									</SubTitle>

									<Uploader
										limit={1}
										labelSize="500x300"
										name="thumb"
										id={`thumb-1`}
										getErrorSize={(v) => setErrorUploadSize(v)}
										getValue={(v) => handleChangeData("thumbnail", v)}
									>
										{error && (
											<ErrorMessage>
												No logramos procesar la imagen, porfavor revisa que sea
												un archivo válido y que tengan un peso menor a 1 MB
											</ErrorMessage>
										)}
										{errorUploadSize && (
											<ErrorMessage>
												El peso total de tu archivo excede 1 MB de tamaño, por
												favor comprime su tamaño del archivo.
											</ErrorMessage>
										)}
									</Uploader>
								</Col>
							</Flex>

							<Flex>
								<Col xs={20} md={18}>
									<SubTitle className="mt:1 mb:1">
										Sube tu imagen de portada
									</SubTitle>

									<Uploader
										limit={2}
										labelSize="1920x1200"
										name="cover"
										id={`cover-1`}
										getErrorSize={(v) => setErrorUploadSize2(v)}
										getValue={(v) => handleChangeData("cover", v)}
									>
										{error && (
											<ErrorMessage>
												No logramos procesar la imagen, porfavor revisa que sea
												un archivo válido y que tengan un peso menor a 2 MB
											</ErrorMessage>
										)}
										{errorUploadSize2 && (
											<ErrorMessage>
												El peso total de tu archivo excede 2 MB de tamaño, por
												favor comprime su tamaño del archivo.
											</ErrorMessage>
										)}
									</Uploader>
								</Col>
							</Flex>
						</Box>
					</Col>
					<Col xs={12} md={10} className="ph:1">
						<Preview data={data} url={url} />

						<Flex>
							<Col className="mt:4">
								<Button
									square
									primary
									loading={requesting}
									disabled={!enableButton && !requesting}
									onClick={() => handleSaveBlog()}
								>
									Registrar blog
								</Button>
							</Col>
						</Flex>
					</Col>
				</Flex>
			</Grid>
		</Layout>
	);
};

export default AgregarBlog;
