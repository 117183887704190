import React from 'react'
import {
  Flex,
  Box,
  SubTitle,
  Text,
  Badge,
} from "@components";

import cover from '@assets/img/cover.jpg'

const Preview = ({data, url}) => {
	return (
		<>
			<SubTitle>Vista previa de SERP Google</SubTitle>
            <Box>
              <picture
                style={{
                  width: "100%",
                  height: "150px",
                  overflow: "hidden",
                }}
              >
                <img
                  style={{
                    marginBottom: "1rem",
                    borderRadius: "5px",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={data?.thumbnail?.urlObject || cover}
                />
              </picture>

              <Text
                xs={12}
                fw={300}
                opacity={0.95}
                style={{
                  width: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                https://digitalbooting.com › blog ›{" "}
                {url || "url-amigable-para-este-blog"}
              </Text>
              <Text xs={20} fw={300} line={1.5} color="#1a0dab">
                {data.seotitle || "Un Titulo SEO impresionante"}{" "}
                {data.leyend && " | Blog"}
              </Text>
              <Text xs={12} fw={300}>
                {data.description ||
                  "Esta es la descripción que aparecerá en google con un máximo de 150 caracteres al momento de indexar el blog."}
              </Text>
              <Text xs={12} fw={300} opacity={0.8} line={1.5}>
                <i>{data.timereading}</i>
              </Text>

              <div className="mt:2">
                <SubTitle>Blog Meta Keyword</SubTitle>
                <Flex>
                  <Badge>{data.metakeyword || 'Keyword principal'}</Badge>
                </Flex>
              </div>

              <div className="mt:2">
                <SubTitle>SEO Meta Keywords</SubTitle>

                {!!data.keywords.length && (
                  <Flex>
                    {data.keywords.map((keyword, index) => (
                      <Badge key={index}>{keyword}</Badge>
                    ))}
                  </Flex>
                )}
              </div>

              <div className="mt:2">
                <SubTitle>Canonical URL</SubTitle>

                <Text color="#1a0dab" xs={12} fw={300} opacity={0.95}>
                  https://digitalbooting.com/blog/
                  {url || "url-amigable-para-este-blog"}
                </Text>
              </div>
            </Box>
		</>
	)
}

export default Preview
